export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.params.category && to.params.problemUri && to.params.servicePointUri) {
    const { category, problemUri, servicePointUri } = to.params;

    const repairServices = await getProblemRepairServicesByRepairServiceUriId(
      servicePointUri as string,
      category as CategoryType,
      problemUri as string
    );

    if (!repairServices || repairServices.length === 0) {
      return navigateTo('/not-found');
    }

    const repairServicesState = useState(
      'repairServicesByProblemUri',
      () => repairServices
    );
    repairServicesState.value = repairServices;
  }
});
